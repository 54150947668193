import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container } from "@material-ui/core";
import AgriFoodManufacturersBenefits from "./AgriFoodManufacturersBenefits";
import AgriFoodManufacturerersServices from "./AgriFoodManufacturerersServices";
import FEServices from "./FEServices";
export default function ForAgriFoodManufacturers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
      


        bannerText={[
          <span key="1">Want to be part of Farm Enterprise Network to source </span>,  <br className="d-lg-block d-none" key="2"></br>,<span key="3">high-quality, authentic, and traceable agricultural raw products </span>,
          <br key="4" className="d-lg-block d-none"></br>,
          <span key="5"> directly from verified Agri-Suppliers </span>,
        ]}
        bannerbodycopy="End to End Traceability | Food Safety | Assured Quality | Supply chain efficiency | Best Pricing | Global Supplier Discovery | Contract Farming"
      />

      <div className="main-wrapper2">
        <Container>
        <AgriFoodManufacturerersServices />
        </Container>
      </div>
        <Container>
        <FEServices />
        </Container>
        {/* <div className="main-wrapper2">

      <Collections title="Current Agricultural Asset Supported"/>
</div> */}
      <div className="MSMEBenfitJourney main-wrapper2 ">
        <Container>
        <AgriFoodManufacturersBenefits />
        </Container>
      </div>

  
      <FooterComponent />
    </>
  );
}
