import React from "react";
import OwnNetwork from "../../images/abn-network-services/manage-network.svg";
import Marketplace from "../../images/abn-network-services/marketplace.svg";
import OnboardingService from "../../images/abn-network-services/onboarding.svg";

import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function ABNMainServices() {
  const services = [

   
     
    {
      img: OwnNetwork,
      title: " Digital Sub-Network",
      bodycopy:
      "FEN Network offers a comprehensive service specializing in owning, building, and managing digital transformation networks, enabling traceability, enhancing production, and optimizing operational efficiency for farm enterprises.",
    },
    {
        img: Marketplace,
        title: "Marketplace",
        bodycopy: " FEN Network offers a dynamic marketplace service, connecting buyers, sellers, investors, and farm enterprises, fostering efficient transactions and strategic partnerships to drive growth and innovation in the digital economy."    },
     
        {
          img: OnboardingService,
          title: "Onboarding Service",
          bodycopy: "FEN Network offers an onboarding service, empowering clients to establish their own network through expert agents, ensuring seamless integration and optimal performance in the digital landscape."    },
          
  ];
  return (
    <div className="main-wrapper2">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Services
      </h2>
      <Row>
        {services.map((e,i) => {
          return (
            <Col md={4} className="d-flex" key={i}>
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
