import React from "react";
import OurPartners from "../OurPartners/OurPartners";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
export default function CommunityPartners() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="partnerbg" bannerText={[<span key="1">Partners who helps Farm enterprise</span>, <br key="2" className="d-lg-block"></br>, <span key="3">and Agri-entrepreneurs</span>]}
        />

      {/* <NetworkBanner /> */}
      <div className="main-wrapper2">
        <OurPartners/>
      </div>
      <FooterComponent />
    </>
  );
}
