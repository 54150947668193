import React, { useState } from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForManufacturers.css";
import { Container } from "@material-ui/core";
import ManufacturerServices from "./ManufacturerServices";
import MSMECoustomerBenefit from "../WebSite/MSMECoustomerBenefit/MSMECoustomerBenefit";

export default function ForManufactures() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
        bannerText={[
          <span key="1">
            Want to become a part of Digital Trust Network to create
            Differentiated,
          </span>,
          <br key="2" className="d-none d-lg-block"></br>,
          <span key="3">Reliable & Authentic products from Agri-assets</span>,
        ]}
      />
      <div className="main-wrapper2">
        <Container>
          <ManufacturerServices />
        </Container>
      </div>

      <Container className="mt-4">
        <MSMECoustomerBenefit />
      </Container>

      <FooterComponent />
    </>
  );
}
