import React from "react";
import StepCards from "../stepCards/StepCards";
import step1 from "../../images/ae-journey/ae1.png";
import step2 from "../../images/ae-journey/ae2.png";

import step3 from "../../images/step2.png";
import step4 from "../../images/ae-journey/ae3.png";
import step5 from "../../images/ae-journey/ae4.png";
import img5 from "../../images/ae-journey/ae5.png";

export default function CFJourney() {
  const stepCardContent = [
    {
      title: "Step 1",
      bodycopy: "Create an account",
      img: step1,
    },
    {
      title: "Step 2",
      bodycopy: "Choose the plan",
      img: step2,
    },
    {
      title: "Step 3",
      bodycopy: "onboard & manage Agri-entrepreneur",
      img: step3,
    },
    {
      title: "Step 4",
      bodycopy: "Publish service catalogue for contract farming",
      img: step4,
    },
    {
      title: "Step 5",
      bodycopy: "Manage order through agri-entrepreneur or in-house",
      img: img5,
    },
    {
      title: "Step 6",
      bodycopy: "Deliver orders to brands",
      img: step5,
    },
  ];
  return (
    <>
      <h2 className="text-center container-title mb-4">
        Journey to get contract farming orders
      </h2>
      <div className="section3BodyContainer justify-content-md-center p-0 pb-5">
        {stepCardContent.map((e, i) => {
          return (
            <StepCards
              key={i}
              src={e.img}
              stepTitle={e.title}
              stepContent={e.bodycopy}
            />
          );
        })}{" "}
      </div>
    </>
  );
}
