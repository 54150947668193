import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import sha256 from "sha256";
import { NotificationManager } from "react-notifications";
// import { useHistory } from "react-router-dom";
// import MainService from "../../Main/Common/MainService";
import MainService from "../../Main/components/Main/Common/MainService";
import "./SigninPageComponent.css";

export default class SigninPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loginObj: {
        abnId: "consumer2",
        username: "",
        password: "",
      },
    };
  }
  componentDidMount = () => {
    const id = localStorage.getItem("Sapna");
  };
  doLogin = (evt) => {
    evt.preventDefault();
    if (!this.state.loginObj.abnId) {
      NotificationManager.warning("Please enter Parent Id");
      return;
    }
    if (!this.state.loginObj.username) {
      NotificationManager.warning("Please enter Username/Email");
      return;
    }
    if (!this.state.loginObj.password) {
      NotificationManager.warning("Please enter Password.");
      return;
    }

    if (this.props.match.params.code < 3 || this.props.match.params.code > 9) {
      NotificationManager.error("Invalid access type.");
      return;
    }
    // const history = useHistory()
    const { abnId, username, password } = this.state.loginObj;
    const encPwd = sha256(password);

    const previousPageUrl = document.referrer;

    MainService.doLogin(abnId, username, encPwd)
      .then((res) => {
        MainService.getConsumerUserInfo(abnId, username)
          .then(() => {
            this.setState({
              isLoading: false,
            });
            NotificationManager.success("Login successfully.");
            setTimeout(() => {
              window.location.href = "/main";
            }, 1000);
          })
          .catch((e) => {
            this.setState({
              isLoading: false,
            });
            return NotificationManager.error(e);
          });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });

        return NotificationManager.error(e);
      });
  };
  goToForgotPwd = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/forgot"
    );
  };
  goToSignup = (evt) => {
    this.props.history.push(
      "/auth/" + this.props.match.params.code + "/signup"
    );
  };
  goToHome = (evt) => {
    this.props.history.push("/");
  };
  onFieldChange = (evt) => {
    let loginObj = this.state.loginObj;
    loginObj[evt.target.name] = evt.target.value;
    this.setState({
      loginObj: loginObj,
    })  };

  clickHandler = () => {
    if (localStorage.getItem("Login") === "true") {
      this.props.history.push("/");
      localStorage.setItem("Login", false);
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    const _code = parseInt(this.props.match.params.code);
    const _class =
      _code === 3
        ? "investment"
        : _code === 4
        ? "veterinary"
        : _code === 6
        ? "grower"
        : _code === 7
        ? "buyer"
        : _code === 8
        ? "govenmentAgency"
        : _code === 10
        ? "offtaker"
        : _code === 11
        ? "technologyprovider"
        : _code === 12
        ? "insuranceprovider"
        : _code === 13
        ? "logisticsprovider"
        : _code === 9
        ? "merchant"
        : _code === 1
        ? "retailer"
        : _code === 5
        ? "agent"
        : _code === 2
        ? "veterinary"
        : _code === 10
        ? "grower"
        : _code === 15
        ? "processor"
        : _code === 16
        ? "driver"
        : _code === 17
        ? "investor"
        : _code === 18
        ? "agriMiner"
        : "default";

    return (
      <Container maxWidth={false} className={"signinPageContainer " + _class}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={11}
            sm={5}
            md={4}
            lg={3}
            className="loginOuterContainer"
          >
            <div className="loginContainer position-relative mx-auto">
              <div className="closeBtn position-absolute">
                {/* <a href="/"> */}
                <span className="iconCnt closeCnt">
                  <i onClick={this.clickHandler} className="material-icons">
                    close
                  </i>
                </span>
                {/* </a> */}
              </div>
              <form onSubmit={this.doLogin}>
                <h2> Grow Big & Reap </h2>

                {}
                {/* <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="abnId"> ABN Id * </InputLabel>{" "}
                  <Input
                  disabled
                    id="abnId"
                    name="abnId"
                    //type="email" //done by srine for using this invalid usetrname
                    type="text"
                    value={this.state.loginObj.abnId}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons"> face </i>{" "}
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />{" "}
                </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="username"> Username * </InputLabel>{" "}
                  <Input
                    id="username"
                    name="username"
                    //type="email" //done by srine for using this invalid usetrname
                    type="text"
                    value={this.state.loginObj.username}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons"> face </i>{" "}
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />{" "}
                </FormControl>

                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="password"> Password * </InputLabel>{" "}
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    value={this.state.loginObj.password}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="material-icons"> vpn_key </i>{" "}
                      </InputAdornment>
                    }
                    require="true"
                    onChange={this.onFieldChange}
                  />{" "}
                </FormControl>

                <FormControlLabel
                  className="rememberMe"
                  control={<Checkbox value="rememberMe" color="primary" />}
                  label="Remember me on this computer"
                />

                <FormControl fullWidth={true}>
                  {this.state.isLoading === false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="submit"
                      disabled={
                        (this.state.username !== "") &
                        (this.state.password !== "")
                          ? false
                          : true
                      }
                    >
                      Sign in
                    </Button>
                  )}{" "}
                  {this.state.isLoading === true && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="loginBtn"
                      type="button"
                      disabled={true}
                    >
                      <CircularProgress className="loginLoader" />
                    </Button>
                  )}
                </FormControl>

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToForgotPwd}
                >
                  Forgot Password ?
                </FormControl>

                {/* <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToSignup}
                >
                  Not registered ? Click to Signup{" "}
                </FormControl> */}

                <FormControl
                  fullWidth={true}
                  className="forgotPwdLink"
                  onClick={this.goToHome}
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  Back Home ?
                </FormControl>
              </form>{" "}
            </div>{" "}
          </Grid>{" "}
        </Grid>
      </Container>
    );
  }
}
