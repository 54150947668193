import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "react-bootstrap";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import "./LogisticsProviders.css";
import LogisticsProvidersServices from "./LogisticsProvidersServices";
import LogisticsProvidersBenefits from "./LogisticsProvidersBenefits";
export default function LogisticsProviders() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="logistic-provider-bg"
        bannerText="Logistc Providers"
      />
      <Container>
        <SolutionEcosystem
          bold="true"
          type="LP"
          bodycopy="FEN Network provides an ecosystem for logistic Providers to ovecome the challenges."
        />
      </Container>
      <div className="main-wrapper2">
        <Container>
          <LogisticsProvidersServices />
        </Container>
      </div>

      <Container className="mt-4">
        <LogisticsProvidersBenefits />
      </Container>
 
      <FooterComponent />
    </>
  );
}
