import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/Agri-Business_Challenges_-_25-06-2024_C-removebg-preview.png";
import Ecosystem from "../../../images/AgriBusinessChallenges/trust.svg";
import Technology from "../../../images/global 2.svg";
import Finance from "../../../images/Capital-Free Scaling.svg";
import loan from "../../../images/Enabling Investments.svg";

// import "./ChallangesSection.css";

export default function AgriBusinessChallanges() {
  const challanges = [
    //   {
    //   title:"Agri-Trust Gap",
    //   description:"Overcoming trust issues in agri-business as assets are provided to farmers, posing a key challenge in fostering reliable partnerships.",
    //   image:Finance
    // },

    {
      title: "Robust Production Process",
      description:
        "There is a need of  a robust production framework that enhances trust and collaboration specifically tailored for track and trace of agricultural assets distributed across the farms  or rural agri-entrepreneur network. The framework should address concerns related to transparency, accountability, and asset life-cycle management.",
      image: Ecosystem,
    },
    {
      title: " Capital-Free Scaling",
      description:
        "Farm enterprises often face challenges in scaling their farming infrastructure without substantial capital investment and addressing skilled resource shortages problem.Therefore, there is need of asset light farming infrastructure.",
      image: Finance,
    },
    // {
    //   title:"Marketing ",
    //   description:"Farmers lack effective marketing tools, highlighting a crucial need for promoting and selling their products. ",
    //   image:Marketing
    // },
    {
      title: "Market Access",
      description:
        " Market access is essential for farm enterprises to tap into both local and global markets, ensuring sustainable profitability and expansion opportunities.",
        image: Technology,

    },

    {
      title: "Enabling Investments ",
      description:
        "Need a system and process to enable farming investments from retail investors, in addition to financial institutions,  who want to invest in agri-assets  or agriculture projects and want to earn profitable returns while also creating impact.",
      image: loan,

    },
  ];
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        Farm Enterprise Challenges
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img
            src={challangeImg}
            alt="challange Image"
            className="mb-md-0 mb-4"
          />
        </Col>

        <Col md={8}>
          <Row className="challangesRow justify-content-between ">
            {challanges.map((e,i) => {
              return (
                <Col md={6} sm={6} xs={10} className="d-flex" key={i}>
                  <div className="challangeCards  mb-md-4 ">
                    <img src={e.image} alt="Challange Icon" className="mb-3" />
                    <h6 className="mb-2">{e.title}</h6>
                    <p className="mb-0">{e.description}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
