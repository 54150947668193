import React from "react";
import Icon1 from "../../images/CustomerBenefit/return-on-investment.svg"
import Icon2 from "../../images/CustomerBenefit/quality-assurance.svg"
import Icon3 from "../../images/CustomerBenefit/time-manager.svg"
import Icon4 from "../../images/CustomerBenefit/no-preservatives.svg"
import Icon5 from "../../images/CustomerBenefit/traceability.svg"

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function LogisticsProvidersBenefits() {
  const benefits = [
    {
      image: Icon1,
      title: "Live updates",
      bodycopy:
        " Allows businesses and customers to track shipments in real-time, providing visibility into the location and status of goods. "
    },
    {
      image: Icon2,
      title: "Improved Transparency  ",
      bodycopy:
        "Enhances trust and communication between clients by offering up-to-date information on delivery progress.    "
    },
    {
        image: Icon3,
        title: "Automated Processes      ",
        bodycopy:
          " Reduces manual tasks and paperwork through automation, leading to quicker processing times and fewer errors."
      },
      {
        image: Icon4,
        title: "Route Optimization       ",
        bodycopy:
          "Helps in planning the most efficient routes, saving time and fuel costs. "
      },
      {
        image: Icon5,
        title: "Reduced Operational costs        ",
        bodycopy:
          " By optimizing routes, reducing fuel consumption, and minimizing idle times, it can lower the overall logistics expenses. "
      },
      {
        image: Icon5,
        title: "Enhanced Security        ",
        bodycopy:
          " Secure Transactions ensures the secure handling of data and transactions, protecting sensitive information. "
      },
      {
        image: Icon5,
        title: "Accurate ETA        ",
        bodycopy:
          " Provides accurate estimated time of arrivals (ETAs), keeping customers informed and setting realistic expectations. "
      },
      {
        image: Icon5,
        title: "Improved communication        ",
        bodycopy:
          " Keeps clients and their customers updated with real-time notifications and alerts about shipment status and any changes. "
      },

  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Logistic Provider Benefits</h2>
      <Row>
        {benefits.map((e,i) => {
          return (
            <Col md={6} key={i}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>

  );
}
