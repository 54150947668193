import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "react-bootstrap";
import AgriRetailerBenefits from "./AgriRetailerBenefits";
import AgriRetailInvestorServices from "./AgriRetailInvestorServices";

const ExploreAgriRetailer = () => {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="investmentContainer-wrapper"
        bannerTitle="Start Investment Now"
      />

      <SolutionEcosystem
        type="ARI"
        bold="true"
        bodycopy=" Process for agri-retail investor to fulfill their needs."
      />

      <div className="main-wrapper2">
      <Container>
        <AgriRetailInvestorServices />
      </Container>
      </div>
      <div className=" subSectionWrapper">
        <Container>
        <h4 className="container-title text-center">
          Agri-retail Investor Benefits
        </h4>
        <AgriRetailerBenefits />
        </Container>
      </div>
     

      <FooterComponent />
    </>
  );
};

export default ExploreAgriRetailer;
