import React from "react";
import { Container } from "@material-ui/core";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import "./InvestmentsComponent.css";
import InvestmentSection2 from "./InvestmentSection2/InvestmentSection2";
import InvestmentServices from "./InvestmentServices/InvestmentServices";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default class ExploreInvestement extends React.Component {
  render() {
    return (
      <>
        <HeaderComponent />
        <UpdatesBanner
          className="investmentContainer-wrapper"
          bannerTitle="Institutional Lenders"
        />

        <SolutionEcosystem
          type="IP"
          bold="true"
          bodycopy=" Process for institutional lenders to fulfill their needs."
        />

        <div className="main-wrapper2">
          <Container>
            <InvestmentServices />
          </Container>
        </div>
        <div className=" subSectionWrapper">
          <Container>
          <h4 className="container-title text-center">
            Institutional Lender Benefits
          </h4>
          <InvestmentSection2 />
          </Container>
        </div>

        <FooterComponent />
      </>
    );
  }
}
