import React from "react";
import { Modal } from "react-bootstrap";
// import "./ClubDetails.css";

export default function CBOClubModal(props) {
  const data = props.resources;
  return (
    <div className="club-details-modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="details-header text-white">
          <Modal.Title id="contained-modal-title-vcenter" className="details-title me-3 me-md-5">
            Asset Types
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="details-body">
        <ul>
              {data.length > 0 ?<>{data.map(resource => (
                        resource.categorytypes.map(category => {
                            if (Array.isArray(category.assettypes)) {
                                return category.assettypes.map((asset, index) => (
                                    <li key={index}>{asset.assettype}</li>
                                ));
                            } else if (category.assettypes && typeof category.assettypes === 'object') {
                                return <li key={category.assettypes.assettype}>{category.assettypes.assettype}</li>;
                            }
                            return null;
                        })
                    ))}</> : <li>No assets found</li>}
                </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
}
