import React from "react";

// import Icon6 from "../../images/gbrFarmerBenefits/breakdown.svg";


import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import OurServiceCard from "./OurServiceCard";

export default function OurServiceCardContainer({services,title,href}) {
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">{title}</h2>
      <Row>
        {services.map((e,i) => {
          return (
            <Col md={4} key={i}>
              <OurServiceCard src={e.image} title={e.title} bodycopy={e.bodycopy} href={e.href}/>
            </Col>
          );
        })}
      </Row>
   
    </div>
    </Container>

  );
}
