import React from "react";
import Icon1 from "../../images/app2.png";
import Icon2 from "../../images/app6.png";
import Icon3 from "../../images/app4.png";
import Icon4 from "../../images/market-positioning.png";
import Icon5 from "../../images/socialmarket.svg";
import Icon6 from "../../images/retail.svg";
import Icon7 from "../../images/farming (1).svg";



import ExploreTechnologyCards from "./ExploreTechnologyCards";
import { Container } from "@material-ui/core";

export default function ExploreTechnologySection2() {
  const feature1 = [
"    Easy to monitor & tracking of agri assets and farms",
"    Quality assurance agri assets and farms",    
"    Monthly and invoice will be generated forte agri assets",
"    Only verified assets will be delivered"  ];

const feature2 =[
  "New business line for managing agri-assets",
"Access to rural agri-entrepreneur partnerships",
"Expanded operational capacity and revenue streams"
]

  const feature3 = [
    "Farm onboarding Management: Create and manage farms efficiently",
    "Offline Registration: requesting for creating users and manage these users.",
  ];
  const feature4 = [
    "onMarketplace provides a wide array of organic products, including fresh produce and processed items, offering consumers a diverse selection of organic choices.",
    "Detailed information about each product, including its source, farming methods, certifications, and nutritional facts, helps customers make informed choices.",
    "The products are guaranteed to be authentic as they meet strict organic certification standards.",
  ];

  const feature5 = [
    "Simplifies tracking and management of contract farming activities.",
    "Provides access to a diverse network of farm enterprises.",
  ];

  const feature6 = [
    "Access a diverse range of farm enterprises and agri-asset investment opportunities.",
    "Simplifies the process for brands to place and manage contract manufacturing orders.",
    "Offers clear and structured agreements between brands and processors.    "
  ];

const feature8 = [
"  Enables sellers to showcase a variety of agricultural assets for purchase.",
"Connects farm enterprises with a global pool of sellers."
]
  const feature9 =[
    " Facilitates large-scale purchases and sales between B2B buyers and farm enterprises.",
    "Offers competitive rates for raw materials through direct interaction between sellers and buyers.    "
  ]

  const feature10 = [
    "Offers a wide selection of animal health and plant care products for holistic farming needs.",
    "Provides high-quality, vetted products to ensure the well-being of livestock and crops.",
    "Access to expert advice and product recommendations for effective care."
  ]
  const appSectionData = [
    {
      id: "AAMCApp",
      href: "https://drive.google.com/drive/folders/1FkI_AlLHbRZNHJCpJd_oVodjztrafV-Q?usp=sharing",
      title: "AAMC App",
      description:"AAMC  is an management application which is used to manage of all the agri assets and the farms in the network.",
      image: Icon2,
      features: feature1,
    },
    {
      id: "FENNetwork",
      href: "/auth/3/signup",
      title: "FEN Network",
      description:"The Farm Enterprise Network (FEN) helps farm enterprises diversify by managing investable agri-assets through a trusted rural agri-entrepreneur network.",
      image: Icon1,
      features: feature2,
      test: "true",
    },

    // {
    //   id: "CBNApp",
    //   href: "http://abn.FENfarming.com/auth/3/signup",
    //   title: "CBN (LTN) App",
    //   description:"CBN (LTN) App is an Community based application which is easy to manage and maintain the community members and their community services in the organisation.",
    //   image: Icon8,
    //   features: feature2,
    //   test: "true",
    // },
    {
      id: "AgriMinerApp",
      href: "https://play.google.com/store/apps/details?id=com.FENapp.agriminer&pli=1?usp=sharing",
      title: "AgriMiner App",
      description:
        "AgriMiner is a robust agricultural application designed to bridge the gap between agricultural organizations and Agri-producers, as well as other users within the agricultural community. This innovative app serves as a central platform for managing farms and facilitating interactions between registered users, ultimately revolutionizing the way agriculture is conducted and managed.",
      image: Icon3,
      features: feature3,
    },
    // {
    //   id: "onMarketplace",
    //   href: "/onMarketplace",
    //   title: "onMarketplace",
    //   description:
    //     " FEN's organic product marketplace offers a diverse selection of high-quality organic goods, from fresh produce to processed items, catering to health-conscious consumers while supporting sustainable farming practices.",
    //   image: Icon4,
    //   features: feature4,
    //   test: "true",
    // },

    // {
    //   id: "SocialonMarketplace",
    //   href: "/social-cf-onmarketplace",
    //   title: "Social CFonMarketplace",
    //   description:
    //     " Social CFonMarketplace, provided by the FEN network, is a platform where brands and food processing enterprises can place contract farming orders with farm enterprises. ",
    //   image: Icon5,
    //   features: feature5,
    //   test: "true",
    // },

    // {
    //   id: "SocialiaaMarketplace",
    //   href: "/iaaMarketplace",
    //   title: "Social iaaMarketplace",
    //   description:
    //     "Social iaaMarketplace opens the door for investors to engage in farm enterprises and agri-asset investments, offering a seamless experience to allocate capital, diversify portfolios, and contribute to the agricultural sector's growth.",
    //   image: Icon6,
    //   features: feature6,
    //   test: "true",
    // },

    // {
    //   id: "AgrocareMarketplace",
    //   href: "/agrocare-marketplace",
    //   title: "Agrocare Marketplace",
    //   description:
    //     "Agrocare Marketplace in FEN is your go-to platform for a wide array of top-notch animal health and plant care products, ensuring the holistic well-being of livestock and crops in one convenient place.",
    //   image: Icon7,
    //   features: feature10,
    //   test: "true",
    // },
    // {
    //   id: "AssetMarketplace",
    //   href: "/asset-marketplace",
    //   title: "Asset Marketplace",
    //   description:
    //     "AssetMarketplace, offered by the FEN network, is a platform where farm enterprises can purchase agricultural assets, and sellers can list their products.",
    //   image: Icon6,
    //   features: feature8,
    //   test: "true",
    // },

    // {
    //   id: "BulkonMarketplace",
    //   href: "/bulk-onmarketplace",
    //   title: "BulkonMarketplace",
    //   description:
    //     "BulkOnMarketplace, provided by the FEN network, is a B2B platform where buyers can purchase raw materials in bulk, and farm enterprises can sell their produce at scale.",
    //   image: Icon7,
    //   features: feature9,
    //   test: "true",
    // },
  ];

  const MarketplaceData = [
    {
      id: "onMarketplace",
      href: "/onMarketplace",
      title: "onMarketplace",
      description:
        " FEN's organic product marketplace offers a diverse selection of high-quality organic goods, from fresh produce to processed items, catering to health-conscious consumers while supporting sustainable farming practices.",
      image: Icon4,
      features: feature4,
      test: "true",
    },

    {
      id: "SocialonMarketplace",
      href: "/social-cf-onmarketplace",
      title: "Social CFonMarketplace",
      description:
        " Social CFonMarketplace, provided by the FEN network, is a platform where brands and food processing enterprises can place contract farming orders with farm enterprises. ",
      image: Icon5,
      features: feature5,
      test: "true",
    },

    {
      id: "SocialiaaMarketplace",
      href: "/iaaMarketplace",
      title: "Social iaaMarketplace",
      description:
        "Social iaaMarketplace opens the door for investors to engage in farm enterprises and agri-asset investments, offering a seamless experience to allocate capital, diversify portfolios, and contribute to the agricultural sector's growth.",
      image: Icon6,
      features: feature6,
      test: "true",
    },

    {
      id: "AgrocareMarketplace",
      href: "/agrocare-marketplace",
      title: "Agrocare Marketplace",
      description:
        "Agrocare Marketplace in FEN is your go-to platform for a wide array of top-notch animal health and plant care products, ensuring the holistic well-being of livestock and crops in one convenient place.",
      image: Icon7,
      features: feature10,
      test: "true",
    },
    {
      id: "AssetMarketplace",
      href: "/asset-marketplace",
      title: "Asset Marketplace",
      description:
        "AssetMarketplace, offered by the FEN network, is a platform where farm enterprises can purchase agricultural assets, and sellers can list their products.",
      image: Icon6,
      features: feature8,
      test: "true",
    },

    {
      id: "BulkonMarketplace",
      href: "/bulk-onmarketplace",
      title: "BulkonMarketplace",
      description:
        "BulkOnMarketplace, provided by the FEN network, is a B2B platform where buyers can purchase raw materials in bulk, and farm enterprises can sell their produce at scale.",
      image: Icon7,
      features: feature9,
      test: "true",
    },
  ]

  return (
    // <>
    //   <Container>
    //     {appSectionData.map((e) => {
    //       return (
    //         <Row
    //           className="subSectionWrapper align-items-center pb-0"
    //           id={e.id}
    //         >
    //           <Col md={6}>
    //             <img className="techology-img mb-3 cursor-initial" src={e.image} />
    //             <h2 className="container-heading1 mt-0">{e.title}</h2>
    //             <p className="container-para mb-md-0 mb-3"> {e.description}</p>
    //             <Button
    //               variant="contained"
    //               color="primary"
    //               className="registerBtn--mod technologyBtn mb-md-0 mb-4 mob-vis"
    //               type="button"
    //             >
    //               <a href={e.href} target="_blank"> {e.test ? "Explore now" : "Download App"} </a>
    //             </Button>
    //           </Col>
    //           <Col md={6}>
    //             <ul className="list-unstyled mb-0">
    //               {e.features.map((e) => {
    //                 return (
    //                   <li className="d-flex">
    //                     <img
    //                       className="feauresIcon me-2"
    //                       src={TickMark}
    //                       alt="tick"
    //                     />
    //                     <p className="container-para">{e}</p>
    //                   </li>
    //                 );
    //               })}
    //             </ul>

    //             <Button
    //               variant="contained"
    //               color="primary"
    //               className="registerBtn--mod technologyBtn mb-md-0 mb-4 mob-hide  d-inline-block"
    //               type="button"
    //             >
    //               <a href={e.href} target="_blank"> {e.test ? "Explore now" : "Download App"} </a>
    //             </Button>
    //           </Col>
    //         </Row>
    //       );
    //     })}
    //   </Container>
    // </>

    <>
    <ExploreTechnologyCards data= {appSectionData}/>

    <div className="subSectionWrapper" id = "Marketplaces">
    <Container> <h2 className="container-title text-center">Marketplaces</h2></Container> 
    <ExploreTechnologyCards data= {MarketplaceData}/>

    </div>
    </>
  );
}
