import React from "react";
import img1 from "../../images/optionalChoice.png";
import img4 from "../../images/double-check.png";
import img5 from "../../images/return-of-investment.png";
import StepCards from '../stepCards/StepCards';

export default function AgriRetailerBenefits() {
  const whyInvestement = [
    {
      img: img1,
      title: "Optional Choice",
      bodycopy: "New Asset Class",
    },
   
    {
      img: img4,
      title: "Tangible",
      bodycopy: "Invest on Living things",
    },
    {
      img: img5,
      title: "Better Returns",
      bodycopy: "Better returns compared to existing options",
    },
  ];
  return (
    // <div className="row benefit-container justify-content-center justify-content-lg-between">
    //   {whyInvestement.map((e) => {
    //     return (
    //       <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12 d-flex mb-3 mb-lg-0 justify-content-center">
    //         <GridCards
    //           src={e.img}
    //           benefitBodycopy={e.bodycopy}
    //           benefitTitle={e.title}
    //         />
    //       </div>
    //     );
    //   })}
    // </div>


<div className="section3BodyContainer benefit-container justify-content-md-center p-0">
{whyInvestement.map((e,i) => {
  return (
    <StepCards
      key={i}
      src={e.img}
      stepTitle={e.title}
      stepContent={e.bodycopy}
    />
  );
})}
</div>
  );
}
