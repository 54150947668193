import React from "react";
import RealTimeVisibility from "../../../images/dtn-network-icons/real-time-visibility.svg";
import ImprovedQuality from "../../../images/dtn-network-icons/improved-quality.svg";
import Traceability from "../../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function AAMCNetworkBenefits() {
  const benefits = [
    {
      img: RealTimeVisibility,
      title: "Real Time Visibility",
      bodycopy:
        " Real-time visibility is a core benefit of DTN Network, offering users immediate access to crucial data and insights across agricultural operations, enabling informed decisions and maximizing efficiency.",
    },
    {
      img: Traceability,
      title: "Traceability",
      bodycopy:
        " Traceability is a distinct advantage of DTN Network, ensuring transparent tracking of agricultural products throughout the supply chain, enhancing accountability and quality assurance for consumers and producers alike.",
    },

    {
      img: ImprovedQuality,
      title: "Improve Quality Production",
      bodycopy:
        "Enhanced quality production is a key benefit of DTN Network, utilizing data-driven insights and advanced tech to optimize agricultural processes, yielding superior results for farmers.",
    },
  ];
  return (
    <div className="">
      <Container className="subSectionWrapper ">
        <h2 className="text-center container-title mb-4">
          Farm Enterprise Benefits
        </h2>
        <Row>
          {benefits.map((e, index) => {
            return (
              <Col key={index} md={4} className="d-flex">
                <BenefitsCard
                  src={e.img}
                  title={e.title}
                  bodycopy={e.bodycopy}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
