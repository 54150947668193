import React from 'react'

export default function AboutList({title, contentlist}) {
  return (
    <>
<h2 className='container-heading2'>{title}</h2>
<ul>
    {contentlist.map((e,i)=>{
      return <li  key={i} className='container-para'>{e}</li>
    })}
    
</ul>
</>
  )
}
