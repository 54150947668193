import React from "react";

import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import "../App.css";
import SolutionEcosystem from "../SolutionEcosystem/SolutionEcosystem";

import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";

import InputProviderBenefits from "../InputProviderBenefits/InputProviderBenefits";
import GBRFarmerService from "../../AgriProducers/Farmer/GBRFarmerService";
export default function ForInputProviders() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="farmUpdateBanner"
        bannerTitle="Agri Input Providers & Agrodealers"
        bannerbodycopy1="We help Agri input providers & Agrodealers to expand their markets"/>
    

      <SolutionEcosystem
        bold="true"
        type="IP"
        bodycopy="Provides financed machinery and asset ecosystem for agri input providers & agrodealers, institutional lenders and farm enterprises"
      />

      <div className="main-wrapper2">
        <GBRFarmerService />
      </div>

      <InputProviderBenefits />

      <FooterComponent />
    </>
  );
}
