import React from "react";
import "./SecondaryNavbar.css";
import { NavLink } from "react-router-dom";

export default function SecondaryNavbar({ props, props2 }) {
  return (
    <div className="Secondary-navbar d-flex justify-content-between">
      <div>
        {props.map((navItem,i) => (
          
          <NavLink 
            key={i}
            to={{
              pathname: navItem.to,
              state: { customProp: navItem.customPropValue }
            }}
            className={`${navItem.disable ? "disabled-link" : ""}`}
          >
            {navItem.link}
          </NavLink>
        ))}
      </div>

      {props2 && (
        <div>
          {props2.map((navItem) => (
            <NavLink 
              key={navItem.to}
              to={navItem.to}
              className={`${navItem.disable ? "disabled-link" : ""}`}
            >
              {navItem.link}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}
