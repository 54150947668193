import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./IAAMarketplace.css";
import ClubMainPage from "../Club/ClubMainPage";
import IaaMarketNavbar from "../IaaMarketNavbar/IaaMarketNavbar";
import AAMCJourney from "../ForAAMC/AAMCJourney";
import { Container } from "@material-ui/core";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
export default function IAAMarketplace() {
  const userTypes = ["AssetOEM"];

  return (
    <>
      <HeaderComponent className="noShadow" />
      <IaaMarketNavbar />
      <UpdatesBanner
        className="IAAMarketplaceBanner text-white"
        bannerText="Get started by introducing yourself to an investor & submit proposal"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
      />
      <div className="subSectionWrapper pb-0">
        <Container>
          <AAMCJourney />
        </Container>
      </div>
      <div className="subSectionWrapper">
        <h2 className="text-center container-title mb-4">
          {" "}
          Institutional Lenders
        </h2>
        <ClubMainPage
          type={userTypes}
          explore="trueinvest"
          registerUser="AssetDeveloper"
        />
      </div>

      <div className="subSectionWrapper">
        <Container>
          <h3 id="agri-assets" className="container-title text-center ">
            Loanable Asset{" "}
          </h3>
          <ProcessedProducts
            type="FARM-MACHINERY"
            userType={["AssetDeveloper"]}
            user="AssetOEM"
            categoryType="f8a159c0-9556-49f4-b92a-5f078e802bf2"
          />
        </Container>
      </div>

      <div className="subSectionWrapper">
        <Container>
          <h3 id="agri-assets" className="container-title text-center ">
            Loanable Agri-inputs{" "}
          </h3>
          <ProcessedProducts
            type="AHP"
            userType={["AssetDeveloper"]}
            user="AssetOEM"
            categoryType= "AGR10"
          />

          <ProcessedProducts
            type="PHP"
            userType={["AssetDeveloper"]}
            user="AssetOEM"
            categoryType= "AGR10"
          />
        </Container>
      </div>
      <FooterComponent />
    </>
  );
}
