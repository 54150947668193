import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "@material-ui/core";
import "./ForBuyers.css";
import BuyerServices from "./BuyerServices";
import BuyerBenefits from "./BuyerBenefits";

export default function ForBuyers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="buyers-background"
        bannerText="B2B Buyers"
        bannerbodycopy="Ensured Quality | Traceability"
           
           />


       <div className="main-wrapper2">
        <Container>
        <BuyerServices />
        </Container>
      </div>
      <Container>
      <BuyerBenefits/>

      </Container>
     
      <FooterComponent />
    </>
  );
}
