import React, { Component } from "react";
import "./Club.css";
import ReactPaginate from "react-paginate";
// import $, { event, merge } from "jquery";
// import HeaderComponent from "../WebSite/Header/HeaderComponent";
// import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container } from "@material-ui/core";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import Form from "react-bootstrap/Form";
// import index from "reactjs-media";
import { Row, Col } from "react-bootstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
// import ClubDetails from "./clubDetailsModal/ClubDetails";
// import SearchBar from "material-ui-search-bar";
// import { NavLink } from "react-router-dom";
// import AgentForm from "../AgentForm/AgentForm";
// import CBOClubModal from "../CBOClubs/CBOClubModal";

class AgribusinessClub extends Component {
  constructor(props) {
    super();
    this.state = {
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      joinwithus: [],
      filterJoinwithus: [],
      offset: 0,
      clubsPageCount: 1,
      perPage: 6,
      currentPage: 0,
      // userTypes: ["FOOD-PROCESSOR", "AssetDeveloper"],
      userTypes: props.type,

      getShow: false,
      showModal: false,
      showClubModal: false,
      showFormModal: false,
      clubname: "",
      userInfoData: [],
      farmgridsearched: "",
      agribusinessName: "",
      roleType: "",
      assetTypes: [],
      edata: [],
      role: {
        roleName: "",
        resources: [],
      },
    };

    this.abortController = new AbortController();
    this.isMountedComponent = false;

  }

  componentDidMount() {
    this.isMountedComponent = true;
    this.getUsers();
  }

  componentWillUnmount() {
    this.isMountedComponent = false;
    this.abortController.abort(); // Cancel pending API requests
  }

  getUsers() {
    this.state.userTypes.forEach((userType) => {
      this.joinClubValues(userType);
    });
  }

  joinClubValues(userType) {
    const url = `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.AssetDeveloperValue}/${userType}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
      signal: this.abortController.signal, // Attach the AbortController signal
    })
      .then((res) => {
        if (res.status !== 200) {
          console.log("Resource not found");
          return null;
        }
        return res.json();
      })
      .then((res) => {
        if (!res || !this.isMountedComponent) return; // Prevent setting state if unmounted

        if (res.users) {
          this.setState((prevState) => ({
            joinwithus: [...prevState.joinwithus, ...res.users],
            filterJoinwithus: [...prevState.filterJoinwithus, ...res.users],
            clubsPageCount: Math.ceil(
              ([...prevState.joinwithus, ...res.users].length) /
                this.state.perPage
            ),
          }));
        } else {
          console.log("No users found in response:", res);
        }
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          console.log("Error fetching data:", err);
        }
      });
  }
  /* Clubs Search */
  requestFarmGridSearch(searchedVal) {
    const { filterJoinwithus } = this.state;
    const filterData = filterJoinwithus.filter((data, index) => {
      return data.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    this.setState({
      joinwithus: filterData,
      clubsPageCount: Math.ceil(filterData.length / this.state.perPage),
    });
  }

  cancelFarmGridSearch = () => {
    this.setState({
      farmgridsearched: "",
    });
    this.requestFarmGridSearch(this.state.farmgridsearched);
  };
  /* EOD  Clubs Search */

  loginHandler(username) {
    localStorage.setItem("clubusername", username);
  }

  /************* Pagination *****************/
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });
  };
  /************* EOD Pagination *****************/
  /************* View Details modal *****************/
  // modalHandler = () =>{
  //   this.setState({showModal:true});
  //   console.log("jvvvvvvvvvvvvvvv")
  // }

  openModal = (adminName) => {
    this.userInfo(adminName);
  };

  CBOModal = (adminName) => {
    this.CBOAssets(adminName);
  };
  agentFormHandler = (clubname) => {
    this.setState({
      showFormModal: true,
      agribusinessName: clubname,
    });
  };
  userInfo(adminName) {
    fetch(`${window.appConfig.userInfo}${window.appConfig.Relam}${adminName}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          userInfoData: res.requestInfo,
          showModal: true,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  CBOAssets(adminName) {
    fetch(
      `${window.appConfig.getRoleType}${window.appConfig.Relam}${window.appConfig.Relam}${adminName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState(
          {
            roleType: res.role,
          },
          () => {
            // Call getAllassets inside the callback to ensure roleType is set in the state
            this.getAllassets(this.state.roleType);
          }
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  getAllassets(role) {
    fetch(
      `${window.appConfig.getRoleTypeResource}${window.appConfig.Relam}${window.appConfig.Relam}${role}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      // .then((res) => {
      //   console.log(res, "API Response");
      //   this.setState({
      //     edata: res
      //   });
      // })
      .then((res) => {
        this.setState({ role: res.role, showClubModal: true });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  closeModal = () => this.setState({ showModal: false });
  closeClubModal = () => this.setState({ showClubModal: false });
  closeFormModal = () => {
    this.setState({ showFormModal: false });
  };
  render() {
    const { resources } = this.state.role;

    const { joinwithus, farmgridsearched, filterJoinwithus } = this.state;
    const clubsList = joinwithus.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    return (
      <>
   
        <Container>
          <div className="club-container ">
            <div id="tendersContainer" className="container-fluid">
        

              {clubsList.length > 0 ? (
                <Row className="justify-content-center">
                  {clubsList.map((joindata, index) => {
                    return (
                      <Col md={6} lg={4} sm={12} className="d-flex mb-3" key={index}>
                        <div className="w-100 d-flex">
                          <div
                            className="list-container bg-light mb-3"
                            style={{ width: "100%" }}
                          >
                            <div className="text-left card-header container-heading1 mt-0 mb-0 d-flex justify-content-between ">
                              <div className="club-heading">
                                {joindata.username}
                              </div>{" "}
                              <div>
                                {" "}
                                <a
                                  className="text-white me-2"
                                  href={`tel:${joindata.phone}`}
                                >
                                  <BsTelephoneFill />
                                </a>
                                <a
                                  className="text-white"
                                  href={`mailto:${joindata.email}`}
                                >
                                  <MdEmail />
                                </a>
                              </div>
                            </div>
                       
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                  <div className="paginationComponent">
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.clubsPageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </Row>
              ) : (
                <div className="card">
                  <p className="card-body container-heading2 mb-0">
                   {this.props.bodycopy ? this.props.bodycopy : <b> Club's Not Found </b>} 
                  </p>
                </div>
              )}
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default AgribusinessClub;
