import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ClubMainPage from '../Club/ClubMainPage';
export default function Agent() {

  const userTypes = ["AssetDeveloper"];
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner
          className="AgentBanner"
          bannerText={[<span key="1">Become a farm enterprise agent to create </span>,
          <br key="2" className="d-lg-block d-none"></br>,
          <span key="3">micro-entrepreneurs in your region</span>]}
          bannerbodycopy="To apply for a job as an farm enterprise agent, please explore farm enterprise "
          href="#AgentForm"
          buttonText5="Explore farm enterprise"
          DlBtn ="true"
          dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&pli=1"
          DownloadText="Download App"
          marginfalse= {true}
        />
        <div id="AgentForm">
        {/* <AgentForm usertype="Agent"/> */}
        <div className="subSectionWrapper">
        <ClubMainPage type={userTypes}  agribusiness="true" explore = "true"/>
      </div>

        </div>
    <FooterComponent/>
    </>
  )
}




