import React, { lazy, Suspense } from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForAAMC.css";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import AAMCBenefits from "./AAMCBenefits";
import AAMCJourney from "./AAMCJourney";
import AAMCContribution from "./AAMCContribution";
import AgriBusinessChallanges from "../WebSite/AgriBusinessChallanges/AgriBusinessChallanges";
import CTA from "../CTA/CTA";
import ABNNET from "../../images/Farm Enterprise Network Infographic Desktop - FEN Network - 22-11-2024 C2.gif";
import ABNNET1 from "../../images/Farm Enterprise Network Infographic Mobile - FEN Network - 22-11-2024 C2.gif";
import GrowerBenefits from "./GrowerBenefits";
import AssetDeveloperPricing from "../pricingTabs/AssetDeveloperPricing";
import OurEcosystem from "./OurEcosystem";
import AAMCServices from "./AAMCServices";


export default function ForAAMC() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="AAMCBanner text-white"
        bannerText="Diversify new revenue streams in your farm enterprise"
        bannerbodycopy={[

          <span key="1">We enable farm enterprises to diversify and expand their operations by </span>,
          <br key="1=2"className="d-lg-block d-none"></br>,
          <span key="3">introducing a new line of business of managing investable agri assets </span>,
          <br key="4"className="d-lg-block d-none"></br>,
          <span key="5">through an approved rural agri-entrepreneur network</span>,
        ]}
        buttonText="Register"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "AssetDeveloper");
        }}
      />
      <Container>
        <TitleDescription
          classname=""
          title="Discovering Farm Enterprises"
          description={[
            "You are a large-scale farmer with diverse agricultural assets—including dairy cows, poultry, honey colonies, swine, sheep/goats, trees, horticulture, and crops—generating over $2 million in annual revenue. With foundational processes established, you seek to improve your focus on environmental impact, production operations, market access, feed quality, agronomy, and veterinary care.",
          ]}
        />
      </Container>

      <div className="main-wrapper2">
        <AgriBusinessChallanges />
      </div>
      <Container
        className="solutionEcosystem-container  subSectionWrapper"
        id="ExploreABN"
      >
        <h2 className="container-title text-center ">Our Solution</h2>
        <p className="container-para text-center mx-0 mx-md-4">
          System and Process to enable asset light farming infrastructure for
          farm enterprise and convert them into Agri asset management company
          (AAMC).
        </p>

        <img
          className="mob-vis1 web-img cursor-initial"
          src={ABNNET}
          alt="Infographics"
        />
        <img
          className="mob-vis1 mob-img cursor-initial"
          src={ABNNET1}
          alt="Infographics"
        />
      </Container>

        <div className="main-wrapper2">
        <Container>

        <AAMCServices />
        </Container>
        </div>
        <Container>

        <AAMCBenefits />
        </Container>

        <div className="main-wrapper2">
        <Container>

        <GrowerBenefits />
        </Container>
        </div>
        <Container>
        <OurEcosystem />
        </Container>
        <div className="main-wrapper2">
        <Container>
        <AAMCContribution />
        </Container>
</div>
<Container className="subSectionWrapper pb-0">
          <h2 className="container-title text-center ">
            Farm Enterprise Pricing
          </h2>
          <AssetDeveloperPricing />
          </Container>
        <div className="subSectionWrapper">
          <CTA
            data="present"
            href="/auth/3/signup"
            heading="Want to manage, build & own rural entrepreneur network"
            btnText="Register Now"
            onClick={() => {
              localStorage.setItem("userType", "AssetDeveloper");
            }}
          />
        </div>
      {/* </Container> */}

      <FooterComponent />
    </>
  );
}
