import React, { memo } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BsTelephoneFill } from "react-icons/bs";



 function StorageProviderModal({ data, Show, onclicked, forecast }) {
    // const[getData, setGetData] = useState(props[0])
  return (
    <div className="technologyModal">
      <Modal
        show={Show}
        onHide={onclicked}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="form-header">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="form-title mb-0 me-5">Contact Information</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <a className="text-decoration-none me-2" href={`tel:${data.phone}`}> <p className="text-black mb-0"><BsTelephoneFill/> {data.phone}</p></a> 
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default memo(StorageProviderModal)
