import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./Terms.css";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
export default class TermsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
      <HeaderComponent/>
        <Container className="SignUpasContainerLightGray terms-container">
          <Grid container spacing={0} justifyContent="center">
            <Grid item md={10}>
              <h2 className="container-title">Terms and Condition</h2>
              <h3>Introduction </h3>
              <p>
                FEN Network Pvt Ltd, often referred to as FEN Network, is a registered company
                based out of Bangalore. The company offers a secure platform for
                investing in livestock without requiring direct involvement in
                its day-to-day management. Capital growth is subject to market
                conditions, and investors who allocate funds to livestock can
                expect to receive annual profit shares.
              </p>
              <h3>Profit Share</h3>
              <p>
                The investor buys livestock units from the Farmer through the
                FEN Network online platform. (A profit share agreement is entered into
                between the investor and FEN Network.) FEN Network has entered into agreements
                with various agri-producers who manage livestock for the
                investors. This agreement is valid for a maximum of 3 years. At
                the termination of the period entered into, the investor can
                either renew the contract or take the value of the livestock
                units once everything is sold by the farmer.
              </p>
              <h3>Value</h3>
              <p>
                The value of the livestock unit is determined by FEN Network based on
                several parameters. The investor can invest for any number of
                units on the FEN Network online platform.
              </p>
              <h3>Managment</h3>
              <p>
                FEN Network ensures practical and scientific livestock rearing practices
                through the agri-producers registered with FEN Network. Qualified
                experts from FEN Network periodically track the quality and health of
                the livestock and see to it that the standards are maintained on
                all the farms. This way, FEN Network ensures risk is minimized to both
                investors and individual agri-producers.
              </p>
              <h3>Growing Together</h3>
              <p>
                Due to the tough economic climate, not many agri-producers are
                in a financial position to increase their land and livestock
                numbers. This model is the answer for the average size farmer,
                as well as any investor, looking to systematically expand his
                farming experience and earn from it. Profit will improve through
                collective buying, collective marketing and constantly adding
                value to the brand. FEN Network is the perfect vehicle for this.
              </p>
              <h3 className="container-heading2">TERMS & CONDITIONS</h3>
              <p>
                Please read these Terms and Conditions carefully,Before using
                this Website/App
              </p>
              <p>
                FEN Network, a company incorporated under the (Indian) Companies Act
                2013, and having its registered office at Bangalore (FEN Network or Our
                or We as applicable) owns and operates the website, www.fennetwork.com
                (hereafter referred to as the “Site”) and the App FEN Network (hereafter
                referred to as the “App”). Investor (the expression Investor
                shall include his/her legal heirs, representatives, successors,
                testators, administrators assignees of the other part)
                (hereafter referred to as “You” or “Your” as applicable) has
                expressed interest in the business of FEN Network and willingness to
                invest in livestock. These terms of use, together with the
                documents referred to in it (hereafter referred to as the
                “Terms”) tells the terms of use on which the investor may make
                use FEN Network's Site and App, as a registered user. Use of this Site/
                App includes accessing, browsing, or registering to use the
                Site/ App. These Terms also inform investor of the terms which
                investor may make use of the services on the Site/ App. Please
                read these Terms carefully before you start to use Our Site/
                App, as these will apply to your use of Our Site/ App. By using
                Our Site/ App and/or making any purchases on Our Site/ App, you
                confirm that you accept these Terms and that you agree to comply
                with them. If you do not agree to these Terms, you must not use
                or make any purchases on Our Site/ App.
              </p>
              <h3>Other applicable terms</h3>
              <p>
                These Terms reference Our Privacy Policy, which outlines how We
                handle any personal data collected from you or provided by you.
                Our Privacy Policy applies to your use of Our Site/App, and by
                using it, you consent to the processing of your personal data in
                accordance with that policy. Additionally, you warrant that all
                data you provide to Us is accurate.
              </p>
              <h3>Changes to these terms</h3>
              <p>
                We may revise these Terms at any time, by amending this page in
                which case, this link will show as ‘Updated or ‘Revised’ on the
                Site/App. Please check these Terms from time to time, to take
                notice of any changes that We make, as they will be binding on
                you.
              </p>
              <h3>Changes to our Site/App</h3>
              <p>
                We may periodically update Our Site/App, including modifying its
                content, display, or format, without prior notice. However, We
                cannot guarantee that Our Site/App or any of its content will be
                entirely free from errors or omissions.
              </p>
              <h3>Accessing our Site/App</h3>
              <p>
                Our Site/App is provided free of charge. We do not guarantee
                continuous or uninterrupted availability of Our Site/App or its
                content. Access to Our Site/App may be temporarily suspended,
                withdrawn, discontinued, or modified due to unavoidable
                circumstances without prior notice. In such cases, we will
                endeavor to provide an alternate Site/App as needed. You are
                responsible for making all necessary arrangements to access Our
                Site/App and ensuring that all individuals who use Our Site/App
                through your internet connection are aware of and compliant with
                these Terms and any other applicable terms and conditions.
              </p>
              <h3>Minor</h3>
              <p>
                You may access and use this Site/App and engage in transactions
                only if you have reached the legally binding contract age as
                specified by the Indian Contract Act, 1872, which is currently
                18 years. In all other cases, you may access the Site/App only
                with the involvement of a parent or guardian.
              </p>
              <h3>Your account and password</h3>
              <p>
                You are required to register on Our Site/App to use or conduct
                transactions. During registration, you must provide a unique
                username and password for accessing Our Site/App. Alternatively,
                you can choose to register using your Facebook login
                credentials. It is your sole responsibility to maintain the
                confidentiality of your account and password and to restrict
                access to your computer to prevent unauthorized use of your
                account. You agree to take responsibility for all activities
                conducted under your account or password.
                <br></br>
                Please ensure the security and confidentiality of your password
                and notify Us immediately if you suspect unauthorized access or
                use of your password by a third party. Ensure that the
                information you provide during registration is accurate and
                complete, and promptly update Us in case of any changes. You can
                access and update your information in the Account section of the
                App.
                <br></br>
                We reserve the right to refuse access to the Site/App, terminate
                accounts, or edit content at our discretion, with or without
                prior notice, in cases of suspicious activity.
              </p>
              <h3> Intellectual property rights</h3>
              <p>
                All content available on this Site/App, including text,
                graphics, logos, button icons, images, audio clips, digital
                downloads, data compilations, and software, is the property of
                FEN Network, its affiliates, or content suppliers. It is protected by
                Indian and international intellectual property laws, including
                copyright, authors' rights, and database rights. The compilation
                of all content on this Site/App is exclusively owned by FEN Network and
                is safeguarded by Indian and international copyright and
                database right laws. The software used on this Site/App is also
                the exclusive property of FEN Network, its affiliates, or software
                suppliers and is protected by Indian and international copyright
                and authors' rights laws.
                <br></br>
                You are granted a limited license to access and make personal
                use of this Site/App but may not download or modify it, or any
                portion thereof, without Our express written consent.
                Specifically, you may not use data mining, robots, or similar
                data gathering and extraction tools to extract substantial parts
                of this Site/App for reutilization without Our express written
                consent. Creating and publishing your own database featuring
                substantial parts of Our Site/App is also not allowed without
                Our written consent. However, these restrictions do not apply to
                the content uploaded by you on Our Site/App.
              </p>
              <h3>License to use this Site/App</h3>
              <p>
                We grant you a limited license to access and use this Site/App
                for personal purposes. However, this license does not include
                the right to download (other than page caching) or modify the
                Site/App or any portion of it, except with Our written consent.
                It also does not permit any form of resale or commercial use of
                the Site/App or its contents, the collection and use of product
                listings, descriptions, or prices, derivative use of the
                Site/App or its contents, downloading or copying of account
                information for the benefit of another merchant, or the use of
                data mining, robots, or similar data gathering and extraction
                tools.
                <br></br>
                Furthermore, you may not reproduce, duplicate, copy, sell,
                resell, visit, distribute, or otherwise exploit any portion of
                this Site/App (including copyrighted material, trademarks, or
                other proprietary information) for commercial purposes without
                Our express written consent or the written consent of Our
                affiliates, as applicable. You are prohibited from framing or
                using framing techniques to enclose any trademark, logo, or
                other proprietary information (including images, text, page
                layout, or form) owned by Us or Our affiliates without express
                written consent. Additionally, using metatags or 'hidden text'
                containing Our or Our affiliates' names, trademarks, or logos
                without express consent is not allowed.
                <br></br>
                Any unauthorized use terminates the permission or license
                granted by Us or Our affiliates, as applicable. You may not use
                Our logo or any proprietary graphic or trademark as part of a
                link without the express written consent of Our affiliates or
                Us, as applicable.
              </p>
              <h3>Your Content and Information</h3>
              <p>
                You retain ownership of all the content and information you post
                on Our Site/App. However, for content that is protected by
                intellectual property rights, such as photos and videos
                (referred to as 'IP content'), you grant Us a non-exclusive,
                transferable, sub-licensable, royalty-free, worldwide, and
                perpetual license to use any IP content that you post on or in
                connection with the Site/App (referred to as 'IP License'). When
                you delete IP content, it is removed in a manner similar to
                emptying the recycle bin on a computer. Please be aware that
                removed content may still exist in backup copies for a
                reasonable period (although it will not be accessible to others
                except as required under lawful orders from a court or other
                legal authority).
              </p>
              <h3>Your obligations</h3>
              <p>
                You hereby acknowledge, understand and agree that your use of
                this Site/ App will be in accordance with applicable law,
                including but not limited to the fact that you shall not host,
                display, upload, modify, publish, transmit, update or share any
                information that – belongs to another person and to which you do
                not have any right to; is grossly harmful, harassing,
                blasphemous, defamatory, obscene, pornographic, paedophilic,
                libelous, invasive of another’s privacy, hateful, or racially,
                ethnically objectionable, disparaging, relating to encouraging
                money laundering or gambling, or otherwise unlawful in any
                manner whatever; harms minors in any way; infringes any patent,
                trademark, copyright or other proprietary rights; violates any
                law for the time being in force; deceives or misleads the
                addressee about the origin of such messages or communicates any
                information which is grossly offensive or menacing in nature;
                impersonates another person; contains software viruses or any
                other computer code, files or programs designed to interrupt,
                destroy or limit the functionality of any computer resource;
                threatens the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign States, or public
                order or causes incitement to the commission of any cognizable
                offence or prevents investigation of any offence or is insulting
                any other nation.
              </p>
              <h3>Services</h3>
              <p>
                You agree, understand, and acknowledge that the Site/App serves
                as an online platform that enables you to request specific
                individuals ('Agri-producers') listed on the site to rear and
                raise livestock, which the Company sells in the market through
                its Site/App. You further agree and acknowledge that the land
                used for providing these services is owned by the respective
                Farmer(s) and does not grant you any leasehold, tenancy, or
                similar license rights.
                <br></br>
                The Services may be gifted or transferred by you to another
                person ('Transferee') as long as the details of the Transferee
                are provided to Us at the time of availing the Service, and we
                have acknowledged the same. Any Transferee shall automatically
                be bound by these Terms.
                <br></br>
                Regarding the Services, you agree, understand, and acknowledge
                that all Services are exclusively provided to you by the Farmer,
                and only the respective Farmer shall be responsible to you for
                any claims, liabilities, and costs resulting from or related to
                the Services. We shall not be responsible for the quality of any
                Services provided to you by a Farmer.
              </p>
              <h3>Other Businesses</h3>
              <p>
                We may provide links to websites of affiliated companies,
                merchants, and certain other businesses on the Site/App. We do
                not examine, evaluate, warrant, or endorse the offerings of
                these businesses/merchants or individuals, nor do we endorse the
                content of their websites. It is important that you carefully
                review their privacy statements and other conditions of use
                before engaging with them. When you access third-party websites
                through Our Site/App, you are responsible for reviewing and
                understanding their terms of use and privacy policies. Your use
                of such websites will be governed by their respective terms of
                use and privacy policies.
              </p>
              <h3>OPT-IN</h3>
              <p>
                By registering for this service, you are consenting to receive
                important communications from us via the email and phone numbers
                listed on your account. These communications are essential for
                the proper functioning of the app and to keep you informed about
                specific events related to your investment. If you wish to opt
                out of these communications, you can do so by contacting us at
                admin@gbrapp.com.com. Please note that opting out may affect the
                app's functionality.
              </p>
              <h3>Disclaimer</h3>
              <p>
                You acknowledge and agree that when accessing the Services on
                the Site/App and transacting with third-party Agri-producers,
                you do so at your own risk and exercise your best and prudent
                judgment before engaging in any transactions through the
                Site/App. You understand and agree that We solely provide a
                platform for you to avail services from Agri-producers, and We
                are not responsible for the services offered by Agri-producers.
                <br></br>
                We shall not be liable or responsible for any actions or
                inactions of the Agri-producers, and We expressly disclaim any
                and all responsibility and liability in this regard.
                Additionally, We do not mediate or resolve any disputes or
                disagreements between you and the Agri-producers.
              </p>
              <h3>Indemnity and Release</h3>
              <p>
                You agree to indemnify Us, Our affiliates, and their respective
                officers, directors, agents, and employees, and hold them
                harmless from any claims, demands, actions, including reasonable
                attorneys' fees, made by any third party or penalties imposed
                due to or arising from:{" "}
              </p>
              <br></br>
              <ul>
                <li className="mb-0">
                  Your breach of these Terms or any document incorporated by
                  reference.
                </li>
                <li className="mb-0">
                  Your violation of any law, rules, regulations, or third-party
                  rights.
                </li>
                <li className="mb-0">
                  Your use of any Services provided by Agri-producers.
                </li>
              </ul>
              <br></br>
              <p>
                You expressly release Us, Our affiliates, and any of their
                officers and representatives from any costs, damages,
                liabilities, or other consequences related to the actions or
                inactions of the Agri-producers. You specifically waive any
                claims or demands that you may have under any statute, contract,
                or otherwise in this regard.
              </p>
              <br></br>
              <p>
              Please note that We will not be responsible for any indirect or
              consequential loss, including loss of profits, revenue, contracts,
              anticipated savings, data, goodwill, or wasted expenses, unless
              such loss was reasonably foreseeable by both you and Us when you
              commenced using the Site/App</p>
              <h3>Waiver</h3>
              <p>
                If you breach these Terms and We take no action, We will still
                be entitled to use Our rights and remedies in any other
                situation where you breach these Terms.
              </p>
              <h3>Termination</h3>
              <p>
                If you violate the letter and/or spirit of these Terms, or
                otherwise create risk or possible legal exposure for Us, We can
                stop providing all or part of the Site/ App to you. We may also
                delete or disable your account at any time without any refund of
                your investment.
              </p>
              <h3>Governing Law and Jurisdiction</h3>
              <p>
                These Terms are governed by, and construed in accordance with
                the laws of India. You agree, as We do, to submit to the
                exclusive jurisdiction of courts in India.
              </p>
              <h3>Grievance Officer</h3>
              <p>
                <span>admin@gbrapp.com</span>
              </p>
              <p>
                The Grievance Officer can be contacted to report abuse, or to
                complain against any Content hosted, transmitted, published,
                updated or shared on Our Site/ App, in the event there are some
                concerns regarding such Content.
              </p>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
