import React from "react";
import img1 from "../../../images/optionalChoice.png";
import img4 from "../../../images/double-check.png";
import img5 from "../../../images/return-of-investment.png";
import StepCards from "../../stepCards/StepCards";

export default function InvestmentSection2() {
  const whyInvestement = [
    {
      img: img1,
      title: "Optional Choice",
      bodycopy: "New Asset Class",
    },
   
    {
      img: img4,
      title: "Tangible",
      bodycopy: "Invest on Living things",
    },
    {
      img: img5,
      title: "Better Returns",
      bodycopy: "Better returns compared to existing options",
    },
  ];
  return (



<div className="section3BodyContainer benefit-container justify-content-md-center p-0">
{whyInvestement.map((e,i) => {
  return (
    <StepCards
      key={i}
      src={e.img}
      stepTitle={e.title}
      stepContent={e.bodycopy}
    />
  );
})}
</div>
  );
}
