import React from "react";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import img3 from "../../images/CustomerBenefit/loss.svg";
import img4 from "../../images/CustomerBenefit/resource.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AAMCBenefits() {
  const benefits = [
    {
      img: img1,
      title: "New Revenue Stream",
      bodycopy: "Introducing a Agriculture asset class as new revenue stream to boost your bottom line and increase profitability.",
    },
    {
      img: img3,
      title: " Reduce Marketing Cost",
      bodycopy: "Farm enterprise can reach a larger customer base without having to manage multiple accounts on different online e-commerce platforms. This reduces the need for separate marketing efforts on each platform.",
    },
    {
      img: img4,
      title: "Efficient Farm Managment Services",
      bodycopy: "Introducing new system and process to offer efficient management of farms in agricultural production by integrating various technologies and stakeholders to optimize farming practices to reduce  operational cost, enhance productivity, and ensure sustainable agricultural practices.",
    },
    {
      img: img2,
      title: "Boosts agricultural yield",
      bodycopy: "Introducing the Converged Agritech Stack (IoT, IoB, AI) and Digital Transformation Network to improve agricultural yields through data-driven decision-making.",
    },
    // {
    //   img: img3,
    //   title: "Create Jobs",
    //   bodycopy:
    //     "Investable trade concept for agriculture asset class will help to create more jobs",
    // },
    // {
    //   img: img4,
    //   title: "Easy Capital Access",
    //   bodycopy:
    //     "Alternate options for Agri-Entreprenuer to raise investment and Investor to invest in tangible assets",
    // },
  ];
  return (
    // <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Farm Enterprise Benefits</h2>
      <Row className="justify-content-center">
        {benefits.map((e,i) => {
          return (
            <Col md={6}  key={i}>
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    // </Container>
  );
}
