import React from "react";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import img3 from "../../images/job-offer.png";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AAMCContribution() {
  const benefits = [
    {
      img: img1,
      title: "New Asset Class",
      bodycopy: "Can Introduce a new tangible asset class for investment opportunities.",
    },
    {
      img: img2,
      title: "Create Agri-entrepreneurs",
      bodycopy: "Farm enterprise transforms agri-producers into agri-entrepreneurs, fostering a dynamic agricultural entrepreneurship ecosystem.      ",
    },
    {
      img: img3,
      title: "Create Jobs",
      bodycopy:
        "Investable trade concept for agriculture asset class will contribute to the creation of additional rural employment opportunities.",
    },
    {
      img: img2,
      title: "Improve Economy of Country",
      bodycopy: "Investable trade in Agriculture bring a cascading increase in Agricultural production and  improves the GDP.",
    },
  ];
  return (
    <div className=" subSectionWrapper ">
      <h2 className="text-center container-title">
        Contribution
      </h2>
      <Row>
        {benefits.map((e,i) => {
          return (
            <Col md={6} className="d-flex"  key={i}>
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
