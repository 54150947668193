
import React from "react";
import HoneyAsset from "../../images/1 (1).png";
import FieldSupport from "../../images/socialmarketplace.jpg";
import OnBoardSupport from "../../images/young-agronomist-showing-some-information-farmer-smartphone-greenhouse-min.jpg"
import { NavLink } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

export default function AgriFoodManufacturerersServices() {
  const services = [

   
    {
        title: "BulkonMarketplace",
        image: HoneyAsset,
        href: "/bulk-onmarketplace",
      },

      {
        title: "Social CFonMarketplace",
        image: FieldSupport,
        href: "/social-cf-onmarketplace",
      },

      {
        title: "Billing Services",
        image: OnBoardSupport,
        href: "/billing-service",
      },
      
  ];

  return (
    <div className="container for-farmer-section2 px-0 subSectionWrapper  pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e,i) => {

          const navLinkProps = {
            to:{
pathname: e.href
            }
          }

          if(e.customPropValue){
            navLinkProps.to.state = {customProp:e.customPropValue}
          }
          return (
            <Col md={4} key={i}>
              <NavLink {...navLinkProps}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
