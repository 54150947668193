import React,{useState} from "react";
import "./PricingCards.css";
import { Button } from "react-bootstrap";
import tick from "../../images/tick1.png";
import { NavLink } from "react-router-dom";
import DemoModal from "../DemoModal/DemoModal";

export default function PricingCards({
  title,
  price,
  annually,
  btnText,
  features,
  href,
  newTab,
  onClick,
  customText,
  id1,id2,
  saving
})

{
  const [demoModal, setDemoModal] = useState(false);
  const [id11, setId1] = useState(id1);
  const [id22, setId2] = useState(id2);
  
    const handleClick = (event) => {
      const button = event.target.id;
      setDemoModal(true)
    };
  
    const hideModal = () =>{
      setDemoModal(false);
      setId2("");
      setId1("");
    }
  return (
    <><DemoModal show={demoModal} onHide={hideModal} id1={id11}  id2={id22}/>

    <div className="pricing-card mb-md-0 mb-3 w-100">
      <div className="pricing-card-title">{title}</div>
      <p className="pricing-card-subtitle">
        A standard plan designed to meet your creative needs.
      </p>
      {price && <><p className="pricing-card-price mb-0">
        {price} INR <span>/month</span>
      </p>
      <p className="pricing-card-frequency mb-4">
        {/* {annually} INR Billed annually */}

        Pay {annually} INR/year, save {saving} INR!
      </p></>}
{customText && <p className="pricing-card-price mb-4">
{customText}  
      </p>}

      {  !newTab ?   <NavLink className="text-white" onClick={onClick} to ={href}><Button variant="contained" color="primary" className="w-100 mb-3">
         {btnText} </Button></NavLink>  
     :
    //  <a text-white href= {href} target="_blank" >
       <Button variant="contained" color="primary"  id={id2} onClick = {handleClick} className="w-100 mb-3">
     Request for Demo
    </Button>
    

 }
     

      <ul className="list-unstyled">
        {features.map((e, i) => {
          return (
            <li className="d-flex"  key={i}>
              <img src={tick} alt="icon" className="me-2" />
              {e}
            </li>
          );
        })}
      </ul>
    </div>
    </>
  );
}
