
import React from "react";
import ProcessingSupport from "../../images/bee-nests-flower-garden.jpg";
import BillingService from "../../images/marketplace/billing-service.jpg";
import { NavLink } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

export default function AgriRetailInvestorServices() {
  const services = [
   


      {
        title: "Social iaaMarketplace",
        image: ProcessingSupport,
        href: "/RetailInvestors",
      },
      {
        title: "Billing Services",
        image: BillingService,
        href: "/billing-service",
      },
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e,i) => {
          return (
            <Col md={6} key={i}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
