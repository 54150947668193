import React from "react";
import { Container } from "@material-ui/core";
import ABNNET from "../../../images/Agri-Producer Infographic GBR Network desktop 22-02-2024 C.gif";
import ABNNET1 from "../../../images/Agri-producer Infographic GBR network Mobile 22-02-2024 C.gif";
import AD from "../../../images/Agro-dealer infographic - Desktop - 09-05-2024 C.gif";
import AD1 from "../../../images/Agro-Dealer infographic - Mobile - 07-06-2024 C.gif";
import AF from "../../../images/Agri-Food Manufacturer - Desktop - 07-06-2024 C .gif";
import AF1 from "../../../images/Agri-Food Manufacturer - Mobile - 07-06-2024 C.gif";
import CBO from "../../../images/CBO - Community based organization desktop 07-06-2024 C.gif";
import CBO1 from "../../../images/CBO - Community based organization - Mobile 10-06-2024 C.gif";
import NO from "../../../images/Network Operator Infographic - GBR Network - Desktop 06-03-2024.gif";
import IP from "../../../images/Farm Machinery Provider Infographic Desktop - FEN Network - 27-08-2024 C.gif";
import IP1 from "../../../images/Farm Machinery Provider Infographic Mobile - FEN Network - 27-08-2024 C.gif";
import FarmersServices from "../../AgriProducers/Farmer/FarmersServices";
import "./SolutionEcosystem.css";
import GBRFarmerService from "../../AgriProducers/Farmer/GBRFarmerService";
import Brands from "../../../images/Brands partner Infographic Desktop - GBR network - 07-06-2024 C.gif";
import Brands1 from "../../../images/Brands partner Infographic Mobile - GBR network - 10-06-2024 C.gif";
import AI from "../../../images/Agri-Investors Infographic Desktop - GBR Network - 30-05-2024 N.gif";
import AI1 from "../../../images/Agri-Investors Infographic Desktop - GBR Network - 30-05-2024 N.gif";
import LP from "../../../images/Logistic Provider Infographic - FEN network - 27-08-2024  N.gif";
import LP1 from "../../../images/Logistic Provider Infographic - FEN network - 27-08-2024  N.gif";
import AFI from "../../../images/Institutional lender Infographic Desktop - FEN Network - 30-07-2024 C.gif";
import AFI1 from "../../../images/Institutional lender Infographic Mobile - FEN Network - 30-07-2024 C.gif";
import ARI from "../../../images/Agri-retail investor Infographic Desktop - FEN Network - 20-08-2024 C.gif";
import ARI1 from "../../../images/Agri-retail investor Infographic Mobile - FEN Network - 20-08-2024 C.gif";

import Buyers from "../../../images/Buyers Infographic Desktop - GBR network - 30-05-2024 N.gif";
import Buyers1 from "../../../images/Buyers Infographic Desktop - GBR network - 30-05-2024 N.gif";

export default function SolutionEcosystem({ type, bodycopy, title, bold }) {
  return (
    <>
      <Container
        className="solutionEcosystem-container  subSectionWrapper"
        id="ExploreABN"
      >
      { title && <h2 className="container-title text-center "> Our Solution</h2>}
      {bold ? (
          <p className="container-title container-title--mod text-center mx-0 mx-md-4">
            {/* <b>{bodycopy}</b> */}
            {bodycopy}
          </p>
        ) : (
          <p className="container-title2 text-center mx-0 mx-md-4">{bodycopy}</p>
        )}{" "}        {type === "AB" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={ABNNET}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={ABNNET1}
              alt="Infographics"
            />
            {/* <div className=" subSectionWrapper mb-0 ">
              <TitleDescription
                titleClass="text-start"
                classname2="text-start"
                classname="mb-0 mt-0"
                title="Who are an Agri-Producers"
                description={[
                  "Agri-producers, also known as agricultural producers or farmers, are individuals or entities engaged in the cultivation and production of crops, livestock, and other agricultural products. These individuals or organizations play a crucial role in sustaining food systems, providing essential commodities for human consumption, and contributing to the economy. Agri-producers employ various techniques and technologies to maximize yields while often striving to minimize environmental impact. They face numerous challenges such as fluctuating market prices, weather uncertainties, and evolving regulatory landscapes. Despite these challenges, agri-producers are essential contributors to global food security and rural livelihoods, with their efforts shaping the agricultural landscape and influencing broader socio-economic dynamics."
                  // 'An agri-entrepreneur can be an aspiring agri-producer  or an organization that can get into different levels of entrepreneurships in the agri-food sector by leveraging  digital  Transformation in agri-food industry. As an Agri-Entrepreneur partner of GBR farming, the aspiring agri-producer or  organizations can truly become a "Farm-to-Factory digital enterprise"',
                  // <br className="d-block pb-4"></br>,

                  // "GBR will provide digital technology and tools for Agri-Entrepreneurs to manage and operate their farms or / and other agri-producers’ farms. GBR will also facilitate the Agri entrepreneurs with financial assistance for installing micro-processing units to process the farm outputs and training to ensure the effective usage of the processing units.",
                ]}
              />

              <AgriBusinessCard />
            </div> */}
            {/* <Container> */}
            <GBRFarmerService/>
            <FarmersServices/>
              {/* <MoneyMakingAssets /> */}
            {/* </Container> */}
          </>
        )}
{
          (type === "AI" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={AI}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={AI1}
                alt="Infographics"
              />
            </>
          ))
        }
{
          (type === "ARI" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={ARI}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={ARI1}
                alt="Infographics"
              />
            </>
          ))
        }
{
          (type === "LP" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={LP}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={LP1}
                alt="Infographics"
              />
            </>
          ))
        }

{
          (type === "AFI" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={AFI}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={AFI1}
                alt="Infographics"
              />
            </>
          ))
        }
        {type === "ADealer" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={AD}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={AD1}
              alt="Infographics"
            />
          </>
        )}
        {type === "CBO" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={CBO}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={CBO1}
              alt="Infographics"
            />
                   {/* <div className=" subSectionWrapper mb-0 ">
              <TitleDescription
                titleClass="text-start"
                classname2="text-start"
                classname="mb-0 mt-0"
                title="Who are Community Based Organisations
                "
                description={[
                  "Community-based organizations (CBOs) in are grassroots groups formed by individuals within a community to collectively address agricultural challenges and enhance rural livelihoods. These organizations play a pivotal role in promoting sustainable farming practices, improving access to markets and resources, and strengthening community ties. By fostering collaboration and knowledge-sharing, CBOs contribute to the overall development of agriculture, empower small-scale farmers, and address specific issues in farming, thereby promoting resilient and inclusive agricultural systems at the local level.                  "
                  // 'An agri-entrepreneur can be an aspiring agri-producer  or an organization that can get into different levels of entrepreneurships in the agri-food sector by leveraging  digital  Transformation in agri-food industry. As an Agri-Entrepreneur partner of GBR farming, the aspiring agri-producer or  organizations can truly become a "Farm-to-Factory digital enterprise"',
                  // <br className="d-block pb-4"></br>,

                  // "GBR will provide digital technology and tools for Agri-Entrepreneurs to manage and operate their farms or / and other agri-producers’ farms. GBR will also facilitate the Agri entrepreneurs with financial assistance for installing micro-processing units to process the farm outputs and training to ensure the effective usage of the processing units.",
                ]}
              />

              <AgriBusinessCard />
            </div> */}
          </>
        )}

        {type === "AF" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={AF}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={AF1}
              alt="Infographics"
            />
          </>
        )}

{type === "Buyers" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={Buyers}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={Buyers1}
              alt="Infographics"
            />
          </>
        )}

{type === "NO" && (
          <>
            {" "}
            <img
              className="mob-vis2 web-img cursor-initial"
              src={NO}
              alt="Infographics"
            />
            <img
              className="mob-vis2 mob-img cursor-initial"
              src={NO}
              alt="Infographics"
            />
          </>
        )}

{
          (type === "IP"   && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={IP}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={IP1}
                alt="Infographics"
              />
            </>
          ))
        }

{
          (type === "Brands" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={Brands}
                alt="Infographics"
              />
              <img
                className="mob-vis2 mob-img cursor-initial"
                src={Brands1}
                alt="Infographics"
              />
            </>
          ))
        }
      </Container>
    </>
  );
}
