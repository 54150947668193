import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ClubMainPage from '../Club/ClubMainPage';
import "./ContractManufacturing.css"
import SocialMarketNavbar from '../SocialMarketNavbar/SocialMarketNavbar';
import CTA from '../CTA/CTA';
import { Container } from 'react-bootstrap';

export default function ContractManufacturing() {

  return (
    <>
    <HeaderComponent className="noShadow"/>
    <SocialMarketNavbar/>

    <UpdatesBanner 
         className="socialMarketplace text-white"
         bannerText={[<span key="1">Start exploring farm enterprise who are looking for </span>,<br key="2" className='d-md-block d-none'></br>, <span key="3">Contract Farming Services </span>]}
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 

{/* <div className="subSectionWrapper">
      <h2 className='container-title mb-md-5 text-center'>Food-Processing Enterprises</h2>

        <ClubMainPage type={["FOOD-PROCESSOR"]}  rfq = "true"/>
      </div> */}
      <div className="subSectionWrapper">
      <h2 className='container-title mb-md-5 text-center'>Farm Enterprises</h2>

        <ClubMainPage type={["AssetDeveloper"]} registerUser="BRAND" rfq = "true"/>
      </div>

      <div className="subSectionWrapper pt-0">
        <Container>
              <CTA
              //  data="present"
               href="/auth/3/signup"
               heading="Send your RFQ for contract farming to all farm enterprises today and unlock new growth opportunities!"
               btnText="Register now for free"
               onClick={()=>{
                 localStorage.setItem("userType", "Brand")
                             }}
              />
              </Container>
            </div>           <FooterComponent/>
    </>
  )
}
