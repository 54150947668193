import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import ClubMainPage from "../Club/ClubMainPage";
import "./AgriBusiness.css";
import SocialMarketNavbar from "../SocialMarketNavbar/SocialMarketNavbar";
import CFJourney from "../ForAAMC/CFJourney";
import { Container } from "@material-ui/core";

export default function AgriBusiness() {

  return (
    <>
      <HeaderComponent className="noShadow" />
      <SocialMarketNavbar />
      <UpdatesBanner
        className="socialMarketplace text-white"
        bannerText={[
          <span key="1">Start your journey  and introduce yourself to a Food Brands and Processors   </span>,
          <br key="2" className="d-md-block d-none"></br>,
          <span key="3">by submitting your services brochure (Catalogue )  </span>,
        ]}
      />
      <div className="subSectionWrapper pb-0">
        <Container>
        <CFJourney />
        </Container>
      </div>
      <div className="subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">Brands</h2>

        <ClubMainPage
          bodycopy="Brands not found"
          type={["Brands"]}
          registerUser="AssetDeveloper"
          catalog="true"
        />
      </div>

      <div className="subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">
          Food Processing Enterprise
        </h2>

        <ClubMainPage
          bodycopy="Food Processing Enterprise not found"
          type={["FOOD-PROCESSOR"]}
          registerUser="AssetDeveloper"
          catalog="true"
        />
      </div>
      <FooterComponent />
    </>
  );
}
